@font-face
	font-family: 'Hoves'
	src: url("./fonts/HovesMedium.otf") format('opentype')
	font-weight: 500
	font-style: normal

@font-face
	font-family: 'Hoves'
	src: url("./fonts/HovesMediumItalic.otf") format('opentype')
	font-weight: 500
	font-style: italic

body
	--color-primary: #0042ff
	font-family: 'Hoves', Verdana, Geneva, Tahoma, sans-serif
	font-weight: 500
	color: var(--color-primary)