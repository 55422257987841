.root
    position: absolute
    left: 0
    top: calc(var(--y) * 100%)
    width: 100%
    height: 100%

    \:global
        .slides
            position: absolute
            left: 2.75vw
            top: 2.75vw
            right: 2.75vw
            bottom: 2.75vw

        .image
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%

            &.active
                z-index: 10

            img 
                position: absolute
                left: 0
                top: 0
                width: 100%
                height: 100%
                object-fit: cover

        h2 
            position: absolute
            z-index: 20
            left: 0
            bottom: 0
            width: 100%
            font-size: 54px
            text-align: center
            text-transform: uppercase
            font-style: italic