.root
   position: fixed
   left: 27.5%
   top: 26.5%
   width: 45%
   height: 47%


   > :global(.slides)
      position: absolute
      width: 100%
      height: 100%
      left: 0
      top: 0
      transition: all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)
        